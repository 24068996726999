const quick_search_form_element = document.getElementById('quickSearchForm');

if(quick_search_form_element) {
    quick_search_form_element.addEventListener('submit', submitQuickSearch, false);
}

function submitQuickSearch(event) {
    event.preventDefault();
    let params = '';
    for(let i=0; i<quick_search_form_element.elements.length; i++ )
    {
        let addParam = true;
        const fieldName = quick_search_form_element.elements[i].name;
        const fieldValue = quick_search_form_element.elements[i].value;
        if(fieldName == 'bedrooms' && fieldValue > 0) {
            for(let j=fieldValue; j<6; j++ ) {
                params += fieldName + '=' + j + '&';
            }
        }
        if(quick_search_form_element.elements[i].type == 'checkbox' && quick_search_form_element.elements[i].checked === false) {
            addParam = false;
        }
        if(quick_search_form_element.elements[i].type == 'submit') {
            addParam = false;
        }

        if(addParam) {
            params += fieldName + '=' + fieldValue + '&';
        }
    }
    setTimeout(function(){
        window.location.href=quick_search_form_element.action + '?' + params;
    },0)
}
