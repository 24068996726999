if (document.body.contains(document.getElementById('contactTemplate'))) {
    document.querySelectorAll('.contact-form').forEach(function(button) {
        var form = this,
            alert = document.querySelector(".alert-danger"),
            errorList = document.querySelector( "ul.errorMessages", form );

        var showAllErrorMessages = function() {
            errorList.forEach(function(elm) {
                elm.innerHTML = '';
            });

            // Find all invalid fields within the form.
            var invalidFields = form.querySelector( ":invalid" ).each( function( index, node ) {

                // Find the field's corresponding label
                var label = document.querySelector( "#" + node.id).siblings( ".label" ),
                    // Opera incorrectly does not fill the validationMessage property.
                    message = node.validationMessage || 'Invalid value.';

                errorList
                    .show()
                    .insertAdjacentHTML("beforeend", "<li><span>" + label.html() + "</span> " + message + "</li>" );
            });
            alert.classList.add('show');
        };

        // Support Safari
        form.addEventListener( "submit", function( event ) {
            if ( this.checkValidity && !this.checkValidity() ) {
                document.querySelector( this ).querySelector( ":invalid" ).first().focus();
                event.preventDefault();
            }
        });

        document.querySelector( "input[type=submit], button:not([type=button])", form )
            .addEventListener( "click", showAllErrorMessages);

        document.querySelector( "input", form ).addEventListener( "keypress", function( event ) {
            var type = document.querySelector( this ).attr( "type" );
            if ( /date|email|month|number|search|tel|text|time|url|week/.test ( type )
                && event.keyCode === 13 ) {
                showAllErrorMessages();
            }
        });
    });
}
