if (document.body.contains(document.getElementById('newsPage'))) {
    let totalArticles = document.getElementById("articles").dataset.total;
    let ajaxUrl = document.getElementById("categoryForm").action;
    let pageLimit = (totalArticles / document.getElementById("articles_per_page").value).toFixed();

    document.getElementById("categoryForm").addEventListener("submit", function (e) {
        e.preventDefault();
    });

    document.getElementById("article_filter").addEventListener('change', function () {
        document.getElementById("selectedCategory").value = this.value;
        paginateArticles();
    });

    document.getElementById("articles_per_page").addEventListener('change', function () {
        paginateArticles();
    });

    document.getElementById("articlesBackward").addEventListener('click', function (e) {
        let currentPage = document.getElementById("currentPage").value;

        document.getElementById("currentPage").value = (Math.max(0, parseInt(currentPage) - 1));

        e.preventDefault();
        paginateArticles();
    });

    document.getElementById("articlesForward").addEventListener('click', function (e) {
        let currentPage = document.getElementById("currentPage").value;
        let itemsToShow = document.getElementById("articles_per_page").value;

        pageLimit = Math.ceil(totalArticles / itemsToShow) - 1;

        document.getElementById("currentPage").value = (Math.min(pageLimit, parseInt(currentPage) + 1));
        e.preventDefault();
        paginateArticles();
    });

    function paginateArticles(elm, e) {
        let currentPage = document.getElementById("currentPage").value;
        let itemsToShow = document.getElementById("articles_per_page").value;
        let categoryToShow = document.getElementById("article_filter").value;

        axios.post(ajaxUrl, {
            itemsNum: itemsToShow,
            category: categoryToShow,
            page: currentPage
        })
            .then(function (response) {
                let result = response.data;

                document.querySelector(".article-list .articles > div").remove();
                document.getElementById('currentPage').value = result.page;
                totalArticles = result.total;
                pageLimit = Math.ceil(totalArticles / itemsToShow) - 1;

                document.getElementById("articles").innerHTML = '';

                if (result.html.length > 0) {
                    result.html.forEach(function (value) {
                        document.getElementById("articles").innerHTML += '<div class="col-sm-12 col-md-6 col-lg-4">' + value + '</div>';
                    });
                }

                // Pagination Buttons Behaviour
                if (totalArticles <= itemsToShow || currentPage == pageLimit) {
                    document.getElementById('articlesForward').classList.add('disabled');
                } else {
                    document.getElementById('articlesForward').classList.remove('disabled');
                }

                if (currentPage > 0) {
                    document.getElementById('articlesBackward').classList.remove('disabled');
                } else {
                    document.getElementById('articlesBackward').classList.add('disabled');
                }
            })
            .catch(function (error) {
                alert('Could not load articles, please try again later.');
            });
    }
}
