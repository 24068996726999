if(window.grecaptcha) {
    grecaptcha.ready(function () {
        let captchaContainer = null;
        let htmlElement = document.getElementById('captcha_container');
        if (htmlElement) {
            let dataSet = htmlElement.dataset;
            if (dataSet.sitekey) {
                let loadCaptcha = function () {
                    captchaContainer = grecaptcha.render('captcha_container', {
                        'sitekey': dataSet.sitekey
                    });
                }
                loadCaptcha();
            }
        }
    });
}
