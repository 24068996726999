window.axios = require('axios');

window.addEventListener('widgetsLoaded', function() {
    require('./bootstrap');
    require('slick-carousel');

    require('./components/sliders');
    require('./components/quick-search');
    require('./components/map');
    require('./components/perfect-scrollbar');

    require('./pages/news');
    require('./pages/contact');

    // OneTrust Cookie consent
    require('./components/onetrust');

    // Captcha
    require('./components/recaptcha');

    // GTM
    const gtm = document.querySelector("meta[name='gtm']");
    if (gtm) {
        let noscript = document.createElement("noscript");
        let iframe = document.createElement("iframe");
        iframe.style.display = "none";
        iframe.style.visibility = "hidden";
        iframe.src = 'https://www.googletagmanager.com/ns.html?id='+gtm.getAttribute("content");
        noscript.appendChild(iframe);
        document.body.insertBefore(noscript, document.body.firstChild);
    }
});

// Home page search
require('./components/google-places-search');

// Scroll to page top
document.getElementById("scrollTop").addEventListener("click", function(e) {
    e.preventDefault();
    window.scrollTo(0, 0);
});
